<template>
  <div class="h-full">
    <table
      v-if="
        this.bannerStatusRequest.value === 'success-getBanners' ||
          this.bannerStatusRequest.value === 'success-sortBanners' ||
          this.bannerStatusRequest.value === 'success-updateBanner' ||
          this.bannerStatusRequest.value === 'success-createBanner' ||
          this.bannerStatusRequest.value === 'success-deleteBanner' ||
          this.bannerStatusRequest.value === 'success-getBannerById'
      "
      class="table banner-table white"
    >
      <thead class="thead-dark">
        <tr>
          <th>
            <v-btn class="mr-1" icon @click="changeDisplayBanner">
              <v-icon v-if="display" color="grey">mdi-eye-off-outline</v-icon>
              <v-icon v-else color="grey">mdi-eye-outline</v-icon>
            </v-btn>
          </th>
          <th>Banner</th>
          <th>Tên Banner</th>
          <th>Url</th>
          <th>Status</th>
          <th>Hành động</th>
        </tr>
      </thead>
      <draggable
        v-model="list"
        tag="tbody"
        @change="sortList(list)"
        @end="drag = false"
        @start="drag = true"
      >
        <tr v-for="item in list" :key="item.id" @mousedown="onSelect(item.id)">
          <td>
            <div v-show="item.id === activatedId">
              <svg
                height="20"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 16 16"
                width="20"
              >
                <path
                  d="M5.5 5a1.5 1.5 0 1 0 0-3a1.5 1.5 0 0 0 0 3Zm0 4.5a1.5 1.5 0 1 0 0-3a1.5 1.5 0 0 0 0 3Zm1.5 3a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0ZM10.5 5a1.5 1.5 0 1 0 0-3a1.5 1.5 0 0 0 0 3ZM12 8a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0Zm-1.5 6a1.5 1.5 0 1 0 0-3a1.5 1.5 0 0 0 0 3Z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
          </td>
          <td>
            <v-img
              :aspect-ratio="16 / 9"
              :src="item.image_url"
              height="50"
              lazy-src="https://picsum.photos/id/11/100/60"
              width="100"
            >
              <template v-slot:placeholder>
                <div class="d-flex align-center justify-center fill-height">
                  <v-progress-circular
                    color="grey-lighten-4"
                    indeterminate
                  ></v-progress-circular>
                </div>
              </template>
            </v-img>
          </td>
          <td>
            <div class="banner-treeselect">
              {{ item.description }}
            </div>
          </td>
          <td>
            <div>
              <a :href="item.url" target="_blank">{{ sortData(item.url) }}</a>
            </div>
          </td>

          <td>
            <div class="flex align-center">
              <v-menu :offset-x="true" open-on-hover top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-switch
                      v-model="item.is_displayed"
                      @change="updateBanner(item)"
                    ></v-switch>
                  </div>
                </template>

                <v-list>
                  <v-hover>
                    <div class="d-flex align-center justify-center px-4">
                      {{ item.is_displayed ? "Ẩn đi" : "Hiện " }}
                    </div>
                  </v-hover>
                </v-list>
              </v-menu>
            </div>
          </td>
          <td>
            <div class="d-flex align-center justify-center">
              <div @click="deleteBanner(item)">
                <v-btn color="error" icon>
                  <v-icon>mdi-delete-forever</v-icon>
                </v-btn>
              </div>
              <div @click="updateBannerModal(item)">
                <v-btn color="primary" icon>
                  <v-icon>mdi-border-color</v-icon>
                </v-btn>
              </div>
            </div>
          </td>
        </tr>
      </draggable>
    </table>
    <div v-else>
      <v-overlay>
        <v-progress-circular
          color="grey lighten-2"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </div>
    <ModalFormBannerCategories></ModalFormBannerCategories>
  </div>
</template>

<script>
import { LIST_MODEL_SITES } from "@/core/constant";
import draggable from "vuedraggable";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ModalFormBannerCategories from "../modals/ModalFormBannerCategories";

export default {
  components: {
    draggable,
    ModalFormBannerCategories
  },
  data() {
    return {
      activeTab: 0,
      LIST_MODEL_SITES,
      list: [],
      activatedId: null,
      display: true
    };
  },
  watch: {
    banners() {
      console.log(1, this.bannersThinkpro);
      if (this.display) {
        this.list = this.filteredBanners.filter(
          banner => banner.is_displayed === 1
        );
      } else {
        this.list = this.filteredBanners;
      }
    }
  },

  computed: {
    banners() {
      return this.$store.getters["BANNER/banners"];
    },

    categoryTrees() {
      return this.$store.getters["PRODUCT_TYPE/categoryTrees"];
    },

    bannersGoodspace() {
      return this.$store.getters["BANNER/bannersGoodspace"];
    },

    bannersThinkpro() {
      return this.$store.getters["BANNER/bannersThinkpro"];
    },

    site() {
      return this.$store.getters["BANNER/site"];
    },

    filteredBanners() {
      if (this.site === "Goodspace") return this.bannersGoodspace;

      return this.bannersThinkpro;
    },

    bannersActive() {
      return this.$store.getters["BANNER/bannersActive"];
    },

    bannerStatusRequest() {
      return this.$store.getters["BANNER/statusRequest"];
    },

    sites() {
      return Object.values(this.LIST_MODEL_SITES);
    }
  },

  async created() {
    await this.getBanners();
    await this.getTreeTypes();
  },

  methods: {
    async updateBannerModal(item) {
      await this.$store.dispatch("BANNER/getBannerById", item.id);
      this.$modal.show({
        name: "modal-form-banner-categories"
      });
    },
    async changeDisplayBanner() {
      this.display = !this.display;
      await this.getBanners();
    },
    addEmptyBanner() {
      this.banners.unshift({
        categories: [],
        description: null,
        image_url: null,
        mb_image_url: null,
        url: null,
        is_displayed: 1,
        sites: [this.sites[this.activeTab].id]
      });
    },
    onUpdate(event) {
      this.tableData = event;
    },
    onSelect(id) {
      this.activatedId = id;
    },
    sortData(text) {
      if (text.length > 40) {
        return text.substring(0, 40) + "...";
      } else {
        return text;
      }
    },
    async getTreeTypes() {
      await this.$store.dispatch("PRODUCT_TYPE/getTreeProductTypes");
    },

    async getBanners() {
      await this.$store.dispatch("BANNER/getBanners");
    },

    deleteBanner(item) {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xóa banner <strong></strong></span>`,
          message: "Bạn có chắc muốn xóa section này không?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              await this.$store.dispatch("BANNER/deleteBanner", item.id);
              await this.getBanners();

              if (this.bannerStatusRequest.value === "success-deleteBanner") {
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã xóa banner"
                  }
                });
              }
            }
          }
        }
      });
    },
    async sortList(list) {
      await this.$store.dispatch("BANNER/sortBanners", list);
    },
    async updateBanner(item) {
      // Upload banner image if changing
      if (item.image_url.slice(0, 5) === "blob:") {
        await this.uploadBannerImage(this.formData);
        // Set uploaded image
        item.image_url = this.bannerUploadedImage;
      }

      // Upload mobile banner image if changing
      if (item.mb_image_url.slice(0, 5) === "blob:") {
        await this.uploadBannerImage(this.formMobileData);
        // Set uploaded image
        item.mb_image_url = this.bannerUploadedImage;
      }

      await this.$store.dispatch("BANNER/updateBanner", {
        data: item,
        bannerIndex: this.bannerIndex
      });

      // Alert
      if (this.bannerStatusRequest.value === "success-updateBanner") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã lưu cập nhật"
          }
        });
      } else if (this.bannerStatusRequest.value === "error-updateBanner") {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.bannerStatusRequest.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    },

    async uploadBannerImage(data) {
      await this.$store.dispatch("BANNER/uploadBannerImage", data);
    }
  }
};
</script>

<style lang="scss">
.banner-table {
  width: 100%;
  border-collapse: separate !important;
}

.banner-table th,
td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.banner-table .v-input__slot {
  margin: 0;
}

.banner-treeselect {
  width: 300px;
}

.banner-table .vue-treeselect__placeholder {
  font-size: 14px;
}
</style>
