<template>
  <div class="banner-header d-flex align-center white">
    <div class="flex justify-space-between">
      <div v-if="currentPage === 'Listing'" class="flex-md-grow-0">
        <v-select
          v-model="site"
          :items="['Thinkpro', 'GoodSpace']"
          class="mx-4"
          dense
          hide-details
          outlined
          width="100"
        ></v-select>
      </div>
      <div v-else class="mx-4 d-flex align-center" @click="backtoList">
        <v-btn color="primary" icon>
          <v-icon size="18px">mdi-arrow-left</v-icon>
        </v-btn>
        <span class="primary--text back-list-text font-weight-bold">
          Danh sách banner
        </span>
      </div>
      <div>
        <div v-if="currentPage === 'Listing'">
          <v-btn
            class=" rounded-lg mr-4"
            color="primary"
            depressed
            @click="createBanner()"
          >
            <v-icon left>mdi-plus</v-icon>
            Tạo mới
          </v-btn>
        </div>
        <div v-else>
          <v-btn
            class=" rounded-lg mr-4"
            color="primary"
            depressed
            @click="confirmCreateBanner()"
          >
            <v-icon left>mdi-plus</v-icon>
            Xác nhận tạo mới
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: String,
      required: true
    }
  },
  computed: {
    site: {
      get() {
        return this.$store.getters["BANNER/site"];
      },
      async set(newValue) {
        this.$store.commit("BANNER/SET_SITE", newValue);
        await this.$store.dispatch("BANNER/getBanners");
      }
    },
    banner() {
      return this.$store.getters["BANNER/banner"];
    },

    formMobileData() {
      return this.$store.getters["BANNER/formMobileData"];
    },

    bannerStatusRequest() {
      return this.$store.getters["BANNER/statusRequest"];
    },
    bannerUploadedImage() {
      return this.$store.getters["BANNER/uploadedImage"];
    }
  },
  methods: {
    async createBanner() {
      await this.$store.dispatch("BANNER/resetBanner");
      this.$emit("createBanner", "BannerCreate");
    },
    async backtoList() {
      this.$emit("listingBanner", "Listing");
    },

    async confirmCreateBanner() {
      // Upload mobile banner image
      await this.uploadBannerImage(this.formMobileData);
      this.banner.image_url = this.bannerUploadedImage;
      // Set Site
      // Request create
      await this.$store.dispatch("BANNER/createBanner", {
        data: this.banner,
        bannerIndex: this.bannerIndex
      });
      // Alert
      if (this.bannerStatusRequest.value === "success-createBanner") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thêm banner thành công"
          }
        });
        this.backtoList();
      } else if (this.bannerStatusRequest.value === "error-createBanner") {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.bannerStatusRequest.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    },

    async uploadBannerImage(data) {
      await this.$store.dispatch("BANNER/uploadBannerImage", data);
    }
  }
};
</script>

<style lang="scss" scoped>
.banner-header {
  height: 64px;
}

.back-list-text {
  cursor: pointer;
}
</style>
