<template>
  <tp-modal max-width="700px" name="modal-form-banner-categories">
    <v-card>
      <v-toolbar
        class="px-5 pb-0 align-start"
        extension-height="40px"
        height="56x"
      >
        <v-toolbar-title class="font-weight-bold"> Tạo mới</v-toolbar-title>

        <v-spacer></v-spacer>

        <div class="flex align-center justify-end">
          <v-btn color="grey darken-1" text @click="closeModal"> Hủy</v-btn>
          <v-btn
            v-if="!banner.id"
            :loading="bannerStatusRequest === 'loading-createBanner'"
            class="cyan lighten-5 rounded-lg ml-4"
            color="primary"
            text
            @click="createBanner()"
          >
            Tạo
          </v-btn>
          <v-btn
            v-else
            :loading="bannerStatusRequest === 'loading-updateBanner'"
            class="cyan lighten-5 rounded-lg ml-4"
            color="primary"
            text
            @click="updateBanner()"
          >
            Lưu
          </v-btn>
        </div>
      </v-toolbar>

      <div class="text-body-1 px-5 mt-4">
        <v-form ref="formBanner" lazy-validation>
          <v-row>
            <v-col class="py-0" cols="12">
              <div class="font-weight-bold mb-2">Mô tả Banner</div>
              <tp-text-field
                v-model="banner.description"
                placeholder="Nhập mô tả"
                validate="required"
              ></tp-text-field>
            </v-col>

            <v-col class="py-0" cols="12">
              <div class="d-flex align-center justify-space-between mb-1">
                <div class="font-weight-bold">Ảnh bìa</div>
              </div>

              <div class="amber lighten-5 rounded text-body-2 px-2 py-1 mb-2">
                Kích thước ảnh yêu cầu
                <span class="font-weight-bold">là 2400 x 640 px</span>
              </div>

              <tp-input-image
                :acceptedFileTypes="['image/*']"
                :height="320 * 2"
                :maxFileSize="500 * 1024"
                :src.sync="banner.image_url"
                :width="1200 * 2"
                allowCompress
                allowFileDimensionValidation
                allowFileSize
                allowFileTypeValidation
                class="flex-grow-1"
                forceType="jpeg"
                required
              ></tp-input-image>
            </v-col>

            <v-col class="py-0" cols="12">
              <div class="flex site-banner">
                <v-checkbox
                  v-model="banner.sites"
                  label="Thinkpro"
                  value="THINKPRO"
                ></v-checkbox>
                <v-checkbox
                  v-model="banner.sites"
                  label="GoodSpace"
                  value="GOODSPACE"
                ></v-checkbox>
              </div>
            </v-col>

            <v-col class="py-0" cols="12">
              <div class="font-weight-bold mb-2">Link banner</div>
              <tp-text-field
                v-model="banner.url"
                placeholder="Nhập link"
                validate="required"
              ></tp-text-field>
            </v-col>

            <v-col class="py-0 mt-1 mb-4" cols="12">
              <div class="font-weight-bold mb-2">Loại sản phẩm</div>
              <treeselect
                v-model="banner.category_ids"
                :multiple="true"
                :options="categoryTrees"
                class="custom-treeselect-category"
                placeholder="Chọn Category"
              ></treeselect>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-card>
  </tp-modal>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: { Treeselect },

  data() {
    return {};
  },

  computed: {
    banner() {
      return this.$store.getters["BANNER/banner"];
    },

    bannerStatusRequest() {
      return this.$store.getters["BANNER/statusRequest"];
    },

    categoryTrees() {
      return this.$store.getters["PRODUCT_TYPE/categoryTrees"];
    },
    bannerUploadedImage() {
      return this.$store.getters["BANNER/uploadedImage"];
    }
  },
  methods: {
    closeModal() {
      this.$refs.formBanner.resetValidation();
      this.$modal.hide({ name: "modal-form-banner-categories" });
    },

    async createBanner() {
      const isValid = this.$refs.formBanner.validate();

      if (!isValid) return;
      // Upload banner image
      if (this.banner.image_url instanceof File) {
        await this.uploadBannerImage(this.banner.image_url);
        this.banner.image_url = this.bannerUploadedImage;
      }

      // Set Site
      // Request create
      await this.$store.dispatch("BANNER/createBanner", {
        data: this.banner,
        bannerIndex: this.bannerIndex
      });
      this.closeModal();

      // Alert
      if (this.bannerStatusRequest.value === "success-createBanner") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thêm banner thành công"
          }
        });
      } else if (this.bannerStatusRequest.value === "error-createBanner") {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.bannerStatusRequest.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    },

    async updateBanner() {
      if (this.banner.image_url instanceof File) {
        await this.uploadBannerImage(this.banner.image_url);
        this.banner.image_url = this.bannerUploadedImage;
      }

      await this.$store.dispatch("BANNER/updateBanner", {
        data: this.banner,
        bannerIndex: this.bannerIndex
      });
      // Alert
      if (this.bannerStatusRequest.value === "success-updateBanner") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã lưu cập nhật"
          }
        });
      } else if (this.bannerStatusRequest.value === "error-updateBanner") {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.bannerStatusRequest.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
      this.closeModal();
    },
    async uploadBannerImage(data) {
      await this.$store.dispatch("BANNER/uploadBannerImage", data);
    },

    validate() {
      this.$refs.form.validate();
    }
  }
};
</script>

<style scoped>
.site-banner {
  gap: 24px;
}
</style>
