<template>
  <div>
    <Header
      @createBanner="onChangeActiveTab"
      :currentPage="currentPage"
      @listingBanner="onChangeActiveTab"
    ></Header>
    <v-divider></v-divider>
    <component :is="currentPage"></component>
  </div>
</template>

<script>
import Listing from "./components/Listing.vue";
import BannerCreate from "./components/BannerCreate.vue";
import Header from "./components/Header.vue";

export default {
  components: {
    Listing,
    BannerCreate,
    Header
  },
  data() {
    return {
      currentPage: "Listing"
    };
  },

  computed: {},
  methods: {
    async onChangeActiveTab(tab) {
      switch (tab) {
        case "BannerCreate":
          this.currentPage = "BannerCreate";
          break;
        default:
          this.currentPage = "Listing";
          break;
      }
    }
  }
};
</script>
